/*------------------------------------*\
    $generel
\*------------------------------------*/

$font-size-default: 48.6667%;
$font-size-md: 55.5834%;
$font-size-lg: 62.50%;
$default-td: .3s;


/*------------------------------------*\
    $fonts
\*------------------------------------*/
$or: "OpenSans-Regular";
$os: "OpenSans-Semibold";
$ob: "OpenSans-Bold";
$ol: "OpenSans-Light";
$oeb: "OpenSans-ExtraBold";

/*------------------------------------*\
    $color
\*------------------------------------*/

$black: #3c3c3c;
$black-2: #2e051d;
$lila-light: #a62077;
$lila-dark: #5d1b47;
$gray: #bdbcbd;
$block-item-color: #666666;
$media-bg: #f7f7f7;
$error: #e0125b;

$green: #54b444;
$yellow: #ddd72c;
$red: #e12f37;
$gray-tab: #eaeaea;
$dark-gray: #6c6c6c;
$light-gray-text: #8f8f8f;
$dark-gray-text: #696969;
$echantillon-text-color: #5c5c5c;

$black-rapport: #3b3b3b;



/*------------------------------------*\
    $override
\*------------------------------------*/
// $grid-gutter-width: 20px;
$breadcrumb-bg: transparent !important;
$breadcrumb-color: #fff;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-active-color: #fff;

$input-height-large: 5.5rem;
$form-group-margin-bottom: 1rem;
$input-border: $lila-dark;
$input-border-focus: $lila-light;

/*------------------------------------*\
    $btn
\*------------------------------------*/

$btn-border-radius-base: 0;
$border-radius-large: 0;

$btn-default-color: $lila-light;
$btn-default-border: transparent;
$btn-default-bg: $lila-light;
$btn-default-color: #fff;

/*------------------------------------*\
    $bloc
\*------------------------------------*/
$bloc-margin-bottom: 3rem;
$choix-item-border-color: #9f9ea1;
$modal-backdrop-opacity: .8;

/*------------------------------------*\
    $modal
\*------------------------------------*/
$modal-title-padding: 2rem;
$modal-header-border-color: transparent;
// $modal-inner-padding: 0;

/*------------------------------------*\
    $nav tabs
\*------------------------------------*/
$border-radius-base:  0;
$nav-tabs-active-link-hover-color: #fff;
$nav-tabs-active-link-hover-bg: $lila-dark;

/*------------------------------------*\
    $progressbar
\*------------------------------------*/
$progress-height: 6px;

$progress-bar-success-bg: $green;
$progress-bar-warning-bg: $yellow;
$progress-bar-danger-bg: $red;
