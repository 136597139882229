/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$arrow-down-name: 'arrow-down';
$arrow-down-x: 112px;
$arrow-down-y: 31px;
$arrow-down-offset-x: -112px;
$arrow-down-offset-y: -31px;
$arrow-down-width: 13px;
$arrow-down-height: 9px;
$arrow-down-total-width: 127px;
$arrow-down-total-height: 98px;
$arrow-down-image: '../images/spritesheet.png';
$arrow-down: (112px, 31px, -112px, -31px, 13px, 9px, 127px, 98px, '../images/spritesheet.png', 'arrow-down', );
$close-lg-name: 'close-lg';
$close-lg-x: 80px;
$close-lg-y: 32px;
$close-lg-offset-x: -80px;
$close-lg-offset-y: -32px;
$close-lg-width: 18px;
$close-lg-height: 18px;
$close-lg-total-width: 127px;
$close-lg-total-height: 98px;
$close-lg-image: '../images/spritesheet.png';
$close-lg: (80px, 32px, -80px, -32px, 18px, 18px, 127px, 98px, '../images/spritesheet.png', 'close-lg', );
$close-name: 'close';
$close-x: 112px;
$close-y: 20px;
$close-offset-x: -112px;
$close-offset-y: -20px;
$close-width: 11px;
$close-height: 11px;
$close-total-width: 127px;
$close-total-height: 98px;
$close-image: '../images/spritesheet.png';
$close: (112px, 20px, -112px, -20px, 11px, 11px, 127px, 98px, '../images/spritesheet.png', 'close', );
$download-name: 'download';
$download-x: 48px;
$download-y: 34px;
$download-offset-x: -48px;
$download-offset-y: -34px;
$download-width: 17px;
$download-height: 16px;
$download-total-width: 127px;
$download-total-height: 98px;
$download-image: '../images/spritesheet.png';
$download: (48px, 34px, -48px, -34px, 17px, 16px, 127px, 98px, '../images/spritesheet.png', 'download', );
$engine-lg-name: 'engine-lg';
$engine-lg-x: 0px;
$engine-lg-y: 0px;
$engine-lg-offset-x: 0px;
$engine-lg-offset-y: 0px;
$engine-lg-width: 48px;
$engine-lg-height: 50px;
$engine-lg-total-width: 127px;
$engine-lg-total-height: 98px;
$engine-lg-image: '../images/spritesheet.png';
$engine-lg: (0px, 0px, 0px, 0px, 48px, 50px, 127px, 98px, '../images/spritesheet.png', 'engine-lg', );
$engine-sm-name: 'engine-sm';
$engine-sm-x: 48px;
$engine-sm-y: 0px;
$engine-sm-offset-x: -48px;
$engine-sm-offset-y: 0px;
$engine-sm-width: 32px;
$engine-sm-height: 34px;
$engine-sm-total-width: 127px;
$engine-sm-total-height: 98px;
$engine-sm-image: '../images/spritesheet.png';
$engine-sm: (48px, 0px, -48px, 0px, 32px, 34px, 127px, 98px, '../images/spritesheet.png', 'engine-sm', );
$engine-xs-lila-name: 'engine-xs-lila';
$engine-xs-lila-x: 36px;
$engine-xs-lila-y: 80px;
$engine-xs-lila-offset-x: -36px;
$engine-xs-lila-offset-y: -80px;
$engine-xs-lila-width: 17px;
$engine-xs-lila-height: 18px;
$engine-xs-lila-total-width: 127px;
$engine-xs-lila-total-height: 98px;
$engine-xs-lila-image: '../images/spritesheet.png';
$engine-xs-lila: (36px, 80px, -36px, -80px, 17px, 18px, 127px, 98px, '../images/spritesheet.png', 'engine-xs-lila', );
$engine-xs-name: 'engine-xs';
$engine-xs-x: 30px;
$engine-xs-y: 50px;
$engine-xs-offset-x: -30px;
$engine-xs-offset-y: -50px;
$engine-xs-width: 26px;
$engine-xs-height: 27px;
$engine-xs-total-width: 127px;
$engine-xs-total-height: 98px;
$engine-xs-image: '../images/spritesheet.png';
$engine-xs: (30px, 50px, -30px, -50px, 26px, 27px, 127px, 98px, '../images/spritesheet.png', 'engine-xs', );
$file-name: 'file';
$file-x: 112px;
$file-y: 0px;
$file-offset-x: -112px;
$file-offset-y: 0px;
$file-width: 15px;
$file-height: 20px;
$file-total-width: 127px;
$file-total-height: 98px;
$file-image: '../images/spritesheet.png';
$file: (112px, 0px, -112px, 0px, 15px, 20px, 127px, 98px, '../images/spritesheet.png', 'file', );
$flag-name: 'flag';
$flag-x: 53px;
$flag-y: 80px;
$flag-offset-x: -53px;
$flag-offset-y: -80px;
$flag-width: 16px;
$flag-height: 18px;
$flag-total-width: 127px;
$flag-total-height: 98px;
$flag-image: '../images/spritesheet.png';
$flag: (53px, 80px, -53px, -80px, 16px, 18px, 127px, 98px, '../images/spritesheet.png', 'flag', );
$item-ok-name: 'item-ok';
$item-ok-x: 0px;
$item-ok-y: 50px;
$item-ok-offset-x: 0px;
$item-ok-offset-y: -50px;
$item-ok-width: 30px;
$item-ok-height: 30px;
$item-ok-total-width: 127px;
$item-ok-total-height: 98px;
$item-ok-image: '../images/spritesheet.png';
$item-ok: (0px, 50px, 0px, -50px, 30px, 30px, 127px, 98px, '../images/spritesheet.png', 'item-ok', );
$map-name: 'map';
$map-x: 56px;
$map-y: 50px;
$map-offset-x: -56px;
$map-offset-y: -50px;
$map-width: 22px;
$map-height: 21px;
$map-total-width: 127px;
$map-total-height: 98px;
$map-image: '../images/spritesheet.png';
$map: (56px, 50px, -56px, -50px, 22px, 21px, 127px, 98px, '../images/spritesheet.png', 'map', );
$minus-name: 'minus';
$minus-x: 0px;
$minus-y: 80px;
$minus-offset-x: 0px;
$minus-offset-y: -80px;
$minus-width: 18px;
$minus-height: 18px;
$minus-total-width: 127px;
$minus-total-height: 98px;
$minus-image: '../images/spritesheet.png';
$minus: (0px, 80px, 0px, -80px, 18px, 18px, 127px, 98px, '../images/spritesheet.png', 'minus', );
$plus-name: 'plus';
$plus-x: 18px;
$plus-y: 80px;
$plus-offset-x: -18px;
$plus-offset-y: -80px;
$plus-width: 18px;
$plus-height: 18px;
$plus-total-width: 127px;
$plus-total-height: 98px;
$plus-image: '../images/spritesheet.png';
$plus: (18px, 80px, -18px, -80px, 18px, 18px, 127px, 98px, '../images/spritesheet.png', 'plus', );
$square-name: 'square';
$square-x: 78px;
$square-y: 50px;
$square-offset-x: -78px;
$square-offset-y: -50px;
$square-width: 19px;
$square-height: 19px;
$square-total-width: 127px;
$square-total-height: 98px;
$square-image: '../images/spritesheet.png';
$square: (78px, 50px, -78px, -50px, 19px, 19px, 127px, 98px, '../images/spritesheet.png', 'square', );
$user-name: 'user';
$user-x: 80px;
$user-y: 0px;
$user-offset-x: -80px;
$user-offset-y: 0px;
$user-width: 32px;
$user-height: 32px;
$user-total-width: 127px;
$user-total-height: 98px;
$user-image: '../images/spritesheet.png';
$user: (80px, 0px, -80px, 0px, 32px, 32px, 127px, 98px, '../images/spritesheet.png', 'user', );
$spritesheet-width: 127px;
$spritesheet-height: 98px;
$spritesheet-image: '../images/spritesheet.png';
$spritesheet-sprites: ($arrow-down, $close-lg, $close, $download, $engine-lg, $engine-sm, $engine-xs-lila, $engine-xs, $file, $flag, $item-ok, $map, $minus, $plus, $square, $user, );
$spritesheet: (127px, 98px, '../images/spritesheet.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
