$icon-font-path: '../fonts/';

@import "components/variables";
@import "components/fonts";


// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

// sprite
@import "spritesheet";
@import "components/icons";
@import "components/override";
@import "components/wysiwyg";
@import "components/module";


//default-pages
@import "pages/default";
@import "pages/footer";


// Responsive: Portrait tablets and up 
@media screen and (min-width: 768px) {
  @import "pages/sm";
}


// Responsive: Portrait desktop and up 
@media screen and (min-width: 1024px) {
   @import "pages/md";
}


// Responsive: Portrait large desktop and up 
@media screen and (min-width: 1200px) {
   @import "pages/lg";

}