html { font-size: $font-size-lg;}

body {
	// background-color: darken(#fff, 20%);
}

.choix-item-title {	
	img, .container-item-title {		
	}
	.container-item-title {
		margin-left: 1.5rem;
		line-height: 1.2;
			.item-title-1 {
				font-size: 2.2rem;
			}
			.item-title-2 {
				font-size: 3.292rem;
			}
	}
}

.container-connexion-form {
	padding: 0 9.5rem 2.5rem 9.5rem;
}

.modal-btn {
	text-align: left;
	.btn {
		margin-bottom: 0;
		margin-right: .5rem;
		padding: .6rem 1rem;
	}
}
.modal-btn-confirm {
	text-align: center; 
	.btn {
		padding: .7rem 4rem;
	}
}


/*------------------------------------*\
    $rapport
\*------------------------------------*/
.rapport-test-median {
		height: 4.2rem;
		.rapport-test-median-content{
			// line-height: 4.2rem;
		}
}