body {
	// background-color: darken(#fff, 20%);
}

/*------------------------------------*\
    $header
\*------------------------------------*/
.container-logo {
	text-align: left;
}

.access-default {
	margin-top: 0;
	float: right;
}

.container-access-center-xs {
	text-align: right;
}
.container-user {
	float: right;
	text-align: left;
}

.editable-bg, .box-page-title { 
	min-height: 32rem;
}
.container-page-title {				
	margin-bottom: 2rem;
	position: absolute;
	width: inherit;
	bottom: 0;
}

.container-connexion-form {
	padding: 0 2.5rem 2.5rem 2.5rem;
}

.modal-btn {
	.btn {
		margin-bottom: 0;
	}
}

/*------------------------------------*\
    $rapport
\*------------------------------------*/
.rapport-test-median {
		height: 5rem;
		.rapport-test-median-content{
			line-height: 5rem;
		}
	> a {		
		padding: 1.2rem 1.2rem .7rem 1.2rem;
	}	
}

.container-rapport-btn {
	.link-files {
		display: block;		
	}
}

.other-tests {
	display: block;
}

.choisir-test-lg {
	padding: 1.5rem 2rem 0 0;
}

