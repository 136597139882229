/*------------------------------------*\
    $input file
\*------------------------------------*/

/* styles de base si JS est activé */
.js .input-file-container {
  position: relative;
  margin-top: .5rem;
}
.js .input-file-trigger {
  display: block;
  padding: .8rem 0;
  border-top: 1px solid $choix-item-border-color;
  border-bottom: 1px solid $choix-item-border-color;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: normal;
  color: #1b5d48;
  @include transition-duration($default-td); 
  
}
.js .input-file {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
 
/* quelques styles d'interactions */
.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
  // background-color: lighten($gray, 20%)
  color: $lila-light;
}
 
/* styles du retour visuel */
.file-return {
  position: absolute;
  width: 100%;
  height: 3.8rem;
  line-height: 3.8rem;
  padding-right: 2rem;
  border-top: 1px solid $choix-item-border-color;
  border-bottom: 1px solid $choix-item-border-color;
  top: 0;
  margin: 0;
  color: #1b5d48;
  background-color: #fff;
  overflow: hidden;
  
}

.file-return:not(:empty) {
}

.input-file-close {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 1.3rem;
}

/*------------------------------------*\
    $breadcrumb
\*------------------------------------*/
.addSlash:after {
  content: "/";
  display: inline-block;
  margin-left: 1rem;
}

/*------------------------------------*\
    $form
\*------------------------------------*/



