/*------------------------------------*\
    $icons
\*------------------------------------*/
.icon-engine-lg {
	 @include sprite($engine-lg);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-engine-sm {
	@include sprite($engine-sm);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-engine-xs {
	@include sprite($engine-xs);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-engine-xs-lila {
	@include sprite($engine-xs-lila);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-user {
	@include sprite($user);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-arrow-down {
	@include sprite($arrow-down);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-item-ok {
	@include sprite($item-ok);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-map {
	@include sprite($map);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-square {
	@include sprite($square);
	display: inline-block;
	background-repeat: no-repeat;
}


.icon-close {
	@include sprite($close);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-close-lg {
	@include sprite($close-lg);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-file {
	@include sprite($file);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-flag {
	@include sprite($flag);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-plus {
	@include sprite($plus);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-minus {
	@include sprite($minus);
	display: inline-block;
	background-repeat: no-repeat;
}

.icon-download {
	@include sprite($download);
	display: inline-block;
	background-repeat: no-repeat;
}
