/*------------------------------------*\
    $fonts
\*------------------------------------*/

@font-face {
  font-family: $or;
  src: url("../fonts/OpenSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: $os;
  src: url("../fonts/OpenSans-Semibold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: $ob;
  src: url("../fonts/OpenSans-Bold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: $ol;
  src: url("../fonts/OpenSans-Light.ttf");
  font-weight: normal;
}

@font-face {
  font-family: $oeb;
  src: url("../fonts/OpenSans-ExtraBold.ttf");
  font-weight: normal;
}
