/*------------------------------------*\
    $base
\*------------------------------------*/

html { 
	font-size: $font-size-default;
}

body {	
	font-family: $or;
}

* {
	word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 !important;	
}

a {
	color: inherit;
	text-decoration: none;
	@include transition-duration($default-td); 
	&:hover {
		text-decoration: underline;
		color: $lila-dark;
		outline: 0;
	}
	&:focus, &:active, &:visited {
		outline: 0;
	}
}

label, strong {
	font-weight: normal !important;
}
.lila-light {
	color: $lila-light;
}

.lila-dark {
	color: $lila-dark;
}

/*------------------------------------*\
    $override
\*------------------------------------*/
.container {
	padding-left: 25px;
	padding-right: 25px;
}

.bba-grid {
	padding-left: 10px;
	padding-right: 10px;
}

.bba-grid-left {
	padding-left: 10px;
}

.bba-grid-right {
	padding-right: 10px;
}

.default-grid {
	padding-left: 0;
	padding-right: 0;
}

.default-grid-left {
	padding-left: 0;
}

.default-grid-right {
	padding-right: 0;
}

.xs-reset {
	@media(max-width: 767px) {
		padding-left: 15px;
		padding-right: 15px;
	}	
}

.sm-reset {
	@media(max-width: 991px) {
		padding-left: 15px;
		padding-right: 15px;
	}	
}

.xs-sm-reset {
	@media(max-width: 991px) {
		padding-left: 15px;
		padding-right: 15px;
	}	
}
/*------------------------------------*\
    $modules
\*------------------------------------*/

/*------------------------------------*\
    $nav
\*------------------------------------*/
nav {
	padding: 1.5rem 0;	
}
	.container-logo {
		text-align: center;
	}

	.container-access-center-xs{
		text-align: center;
	}

.access {
	min-height: 7rem;
	display: inline-block;
	vertical-align: center;
	background-color: #fff;
	&:hover {
		text-decoration: none !important;
		background-color: $lila-dark;		
	}

	.container-btn-text {
		display: inline-block;
		margin-left: .5rem;		
		text-align: left;

		.btn-text-1 {
			font-family: $os;			
		}
		.btn-text-2 {
			font-family: $ob;			
			span {
				text-transform: uppercase;
			}
		}
	}
}

.access-default {
	margin: 1rem auto auto auto;		
	padding: 1.5rem;
	background-color: $lila-light;	

		.btn-text-1 {			
			font-size: 1.405rem;
			color: $gray;
		}
		.btn-text-2 {			
			font-size: 1.359rem;
			color: #fff;
		}
}

.access-lg {
	padding: 1.5rem 2rem;

	.btn-text-1 {			
		font-size: 1.6rem;
		color: $lila-light;		
	}
	.btn-text-2 {			
		font-size: 2rem;
		color:  $lila-dark;			
	}

	&:hover > .container-btn-text {
		.btn-text-1 {
			color: $gray
		}
		.btn-text-2 {
			color: #fff
		}		
	}
}

.access-lg-default {
	.btn-text-1 {			
		font-size: 1.6rem;
	}
	.btn-text-2 {			
		font-size: 2rem;
	}	
}

.access-lg-extra {
	.container-btn-text {
		margin-left: 2rem;
		line-height: 1.2;
	}
		.btn-text-1 {			
			font-size: 2.017rem;
		}
		.btn-text-2 {			
			font-size: 2.617rem;
		}
}

.access-icon {	
	display: inline-block;
	vertical-align: bottom;	
}



/*------------------------------------*\
    $layout
\*------------------------------------*/
.layout {
	background: url('../images/layout.png');
	background-size: cover;		
}
.editable-bg {	
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}


.layout-layer {
	height: 100%;
	color: #fff;
	text-align: center;
}

.slogan {
	max-width: 80rem;
	margin: 17rem auto 20rem auto;
  text-align: center;	
}

	.slogan-img {
		display: inline-block;
	}

	.slogan-h1 {		
		padding-top: 2.5rem;
		padding-bottom: 3rem;
		font-family: $ob;
		font-size: 6.4rem;	
		& + span {			
			display: block;
			max-width: 19.6rem;
			height: 1px;
			margin: 0 auto;
			background-color: #fff;
		}
	}

	.slogan-h3 {
		padding-top: 3.5rem;
		padding-bottom: 4.5rem;
		font-family: $or;
		font-size: 3.2rem;
	}

/*------------------------------------*\
    $page presentation
\*------------------------------------*/
.container-user {
	margin-top: 1.7rem;
	margin-right: 1.5rem;
	text-align: center;

	.user, .name {
		display: inline-block;
		vertical-align: middle;	
	}

	.name {
		position: relative;
		font-size: 1.3rem;
		margin-left: -3rem;
		margin-top: 1rem;

		ul {
		  padding: 0;
		  margin: 0;
		  list-style:none;

		  position: absolute;
		  z-index: 1000;
		  max-height: 0;
		  left: 0;
		  right: 0;
		  overflow:hidden;
		  transition: .8s all .3s;
		}

		li{
			display:inline-block;
			position:relative;
		}
		ul li{
			display:inherit;
		}

		li:hover ul{
		  max-height: 15em;
		}
	
		/* background des liens sous menus */
		li li{
			background: $lila-dark;
		}
		
		/* les a href */
		a {
			text-decoration: none;
			display: block;
			font-family: $os;
			color: #fff;

		}

		li ul  li a {
  		padding: .5rem;
  		&:hover {
				text-decoration: underline;
  		}
		}
		

		.hallo {
			font-family: $os;
			color: $lila-light;
		}
		.name-user {
			display: inline-block;
			font-family: $ob;
			text-transform: uppercase;
			color: $black-2;
			text-decoration: none;
			letter-spacing: .1rem;
			&:hover {
				text-decoration: none;
				color: $black-2;
			}
			&::after {
				content: "";
				display: inline-block;
				margin-left: .8rem;
				@include sprite($arrow-down);
		  	width: $arrow-down-width;
		  	height: $arrow-down-height;	
			}
		}
	}

	
}
	.box-page-title {
		position: relative;		
	}
	.container-page-title {		
		margin-top: 3rem;	
	}
		.page-title {
			font-family: $ol;
			font-size: 5.543rem;
			color: #fff;
		}

			.container-access-page-title {
				margin-top: 3rem;
			}

/*------------------------------------*\
    $page body
\*------------------------------------*/
.page-body {
	padding-top: 3rem;
}

	.text-header {
		font-size: 2.8rem;
	}
	.text-header-1 {
		font-size: 2.8rem;
		color: #fff;
	}

	.page-body-title {
		font-family: $ob;
		font-size: 3.6rem;
		
		strong {
			position: relative;
			top: 1.5rem;
			font-size: 7.131rem;
		}
	}

	.page-body-title-1 {
		margin-top: 3rem;
		color: $lila-dark;
		strong {
			color: $lila-light;
		}
	}

	.page-body-title-2 {
		margin-top: 1rem;
		color: #fff;
		strong {
			color: $lila-light;
		}
	}


	.container-bloc {
		margin-top: 4.5rem;
		margin-bottom: 5rem;
	}
		.container-bloc-item {			
			padding: 2.5rem;
			margin-bottom: $bloc-margin-bottom;
			text-align: center;
			background-color: #fff;			

			.container-img {
				margin-top: 2.5rem;
				img {
					display: inline-block;
				}				
			}
			.bloc-item-title {
				font-family: $ob; 
				color: $lila-dark;
			}
			.bloc-item-text {
				font-size: 1.8rem;
			}
		}

		.container-bloc-item-dynamic {
			min-height: 36rem;
			border: 1px solid $lila-light;

			@media screen and(min-width: 768px) and (max-width: 991px) {
				min-height: 41rem;
			}
			@media screen and(min-width: 992px) and (max-width: 1199px) {
				min-height: 40rem;
			}

			.bloc-item-title {
				margin-top: 2rem;
				font-size: 2.4rem;
			}
			.bloc-item-text {
				margin-top: .5rem;	
			}			
		}

		.container-bloc-item-static {
			margin-top: .5rem;
			margin-bottom: 1.5rem;
			min-height: 25rem;
			@media screen and(min-width: 768px) and (max-width: 1023px) {
				min-height: 30rem;
			}
			.container-img {
				margin-top: 4.5rem;						
			}

			.bloc-item-title {
				margin-bottom: 3rem;
				font-size: 2.2rem;
				color: $lila-dark;
			}
			.bloc-item-text {
				margin-top: 2rem;
				color: $lila-dark;				
			}		

		}

		/*------------------------------------*\
		    $page-resultat
		\*------------------------------------*/
		.page-resultat {
			padding-top: 6.5rem;
		}

		/*------------------------------------*\
		    $container-access-youtube
		\*------------------------------------*/
		.container-access-youtube {
			margin-top: 1px;
			min-height: 13.5rem;
			padding: 2.5rem;
			text-align: center;
			background-color: $lila-light;


			.container {
				padding-left: 20px;
				padding-right: 20px;
			}
				.video-container {
					position: relative;
					padding-bottom: 56.25%;
					padding-top: 30px; height: 0; overflow: hidden;
				}

				.video-container iframe, .video-container object, .video-container embed {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
				}

		}

		/*------------------------------------*\
		    $page-choix-test
		\*------------------------------------*/
		.page-choix-test {
			padding-top: 7rem;
			overflow-x: hidden;
			position: relative;
			 .container {
			 	background-color: #fff;
			 }
			> span {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#fff, .75);
			}
		}		

			.container-bloc-choix {				
				padding: 0 .5rem;				
			}

		
			.choisir-test-grid {			
				position: relative;				
				@include transition-duration(1s); 			
				z-index: 2;
			}

				.bloc-choix-item {
					min-height: 50rem;
					// max-width: 36rem;
					padding: 2.5rem 3.5rem;
					border: 1px solid $lila-dark; 
					margin-bottom: $bloc-margin-bottom;
					position: relative;
					@media screen and(min-width: 1024px) and (max-width: 1199px) {
						min-height: 65rem;
					}
				}

					.choix-item-title {
						margin-bottom: 2rem;
						img, .container-item-title {
							display: inline-block;
							vertical-align: middle;
						}
						.container-item-title {
							margin-left: 1.5rem;
							text-transform: uppercase;
							line-height: 1.2;
								.item-title-1 {
									font-family: $ob;
									font-size: 2.2rem;
									color: $lila-light;
								}
								.item-title-2 {
									font-family: $or;
									font-size: 3.292rem;
								}
						}
					}

					.choix-item-text, .choix-item-text-choisir-test-lg {
						padding: 3rem 0 1.5rem 0; 
						border-top: 1px solid $choix-item-border-color;							
						font-size: 1.5rem;
						line-height: 1.6;
						p {
							font-family: $os;
							margin-bottom: 1.5rem;
							strong {
								font-family: $ob;
							}
						}
						
					}
										

					.input-file-container {
						display: block;
						margin-bottom: 1rem;
					}

					.choix-item-container-btn{
						position: absolute;
						bottom: 2.5rem; 						
						width: 80.5%;
						
						@media screen and (min-width: 480px) and (max-width: 767px) {
							width: 88%;
						}

						@media screen and (min-width: 678px) and (max-width: 991px) {
							width: 92%;
						}
					}

					.container-btn-icon {
						display: inline-block;
							vertical-align: middle;
						i {
							position: relative;
							top: .3rem;
						}	
					}

					.btn-analyse{
						padding-top: .7rem;
						padding-bottom: .7rem; 
					}
					.btn-analyse-icon {
						& + span {
							top: -.6rem;
						}

						&::before {
							content: "";
							display: inline-block;
							margin-right: 1rem;
							@include sprite($engine-xs);
					  	width: $engine-xs-width;
		  				height: $engine-xs-height;	
						}
					}

					.btn-test-icon {
						&::before {
							content: "";
							display: inline-block;
							margin-right: 1rem;
							@include sprite($square);
					  	width: $square-width;
		  				height: $square-height;	
						}
					}

					.btn-rapport-icon {
						&::before {
							content: "";
							display: inline-block;
							margin-right: 1rem;
							@include sprite($map);
					  	width: $map-width;
		  				height: $map-height;	
						}
					}



.choisir-test-lg {
	padding: 1.5rem 0 0 0;
	top: 0;
	margin-bottom: 2.5rem;
	border: 1px solid $lila-dark; 
	background-color: #fff !important;

	.bloc-choix-item-choisir-test-lg {
		min-height: 56rem;
		padding: 2.5rem 3.5rem;
		margin-bottom: $bloc-margin-bottom;
		position: relative;	
	}
	
	.close-choisir-test-lg {
		display: block;
		margin-bottom: 1.5rem;		
		text-align: right;
		font-family: $ob;
		font-size: 1.3rem;
		text-decoration: none;
		color: $black;
		text-transform: uppercase;
		letter-spacing: 1px;
		position: relative;
		z-index: 2;
		& span {
			position: relative;
			top: -6px;
		}

		@media screen and (max-width: 767px){
			margin-right: 2rem;
		}
	}
}

	.media-list-analyse {
		padding: 3rem 3rem 1.5rem 3rem;
		margin-bottom: 2rem;	
		background-color: $media-bg;	
		@include transition-duration($default-td); 
		> .media-left {
			padding-right: 3rem;
			vertical-align: middle;
			& .checkbox {
			  left: -1rem;

				input {
					display: none;

					&:checked + .customized-cb {
						border: none !important;
						background-color: $lila-dark;

					}
					&:checked + .customized-cb:after {
						content: "";
						display: block;												
						@include sprite($square);
				    width: $square-width;
				    height: $square-height;
					}
				}

				.customized-cb {
					background-color: #fff;
					border: 2px solid $lila-light;
					width: 19px; height: 19px;
					display: inline-block;
					vertical-align: middle;
					position: relative;
				}
				
			}
		}

		.media-body {
			.media-heading {
				font-family: $ob;
				font-size: 2rem;
				color: $lila-light;
				i {
					margin-right: 1.5rem;
					position: relative; top: .2rem;
				}
			}

			p {
				font-family: $os;
				font-size: 1.4rem;	
				color: $lila-dark;
				line-height: 1.35;		
			}
		}		
	}

	.media-list-analyse-active {
		background-color: $lila-dark;		
		.media-body {
			.media-heading {				
				color: #fff;
			}
			p {					
				color: #fff;				
			}
		}	
	}


	.choix-item-btn {
		text-transform: uppercase;
		letter-spacing: .1rem;
	}

		.container-rapport {
			padding-top: 2rem;
			border-top: 1px solid	$choix-item-border-color;

			.rapport-item {
				margin-top: 0;
				margin-bottom: .5rem;
				font-family: $os;
				font-size: 1.6rem;
				label {
						&::before {
						content: "";
						display: inline-block;	
						position: relative;
						top: .3rem;	
						margin-left: -2rem;
						@include sprite($file);
				    width: $file-width;
				    height: $file-height;				
					}
					input {
						display: none;
					}
					span {
						margin-left: .5rem;
					}

				}
			}
			.rapport-item-active {
				color: $lila-light;
			}
		}
/*------------------------------------*\
    $creation compte
\*------------------------------------*/
.container-creation-compte {
	margin-top: 4.5rem;
	// background-color: lightblue;
}

.creation-compte-name {
	margin-bottom: 1rem;
}

.container-info-creation-compte {
	margin-top: 2.5rem;
	padding-top: .5rem;
		
	.slogan-h1 {
		padding: 1.5rem 0 2rem 0;
		font-size: 2.6rem;
		color: $lila-dark;
		line-height: 1.15;
		& + span {			
			max-width: 7.5rem;
			margin: 0;
			height: 1px;
			background-color: $lila-dark;
		}
	}
	.slogan-h3 {
		padding: 2rem 0 2rem 0;
		font-family: $or;
		font-size: 1.8rem;
		color: $lila-light;
		line-height: 1.15;
	}

	.access {
		float: left;
	}
}

.container-captcha {
	padding: 1rem 0;
}

/*------------------------------------*\
    $modal
\*------------------------------------*/
.container-connexion-form {
	font-family: $os;
	padding: 0 1.5rem 1.5rem 1.5rem;
	h1 {
		font-family: $ob;
		font-size: 2.4rem;
		text-transform: uppercase;
	}
	form {
		margin-top: -.5rem;
		.form-group {
			font-weight: normal !important;			
			label {
				font-family: $os;
				font-size: 1.4rem;
				color: $error;
				font-weight: normal !important;
			}
		}
	}
	.checkbox {
	}
}

.link-connexion {
	margin: 1.5rem auto;
	padding-top: .5rem;	
	font-size: 1.3rem;
	> a {
		margin-right: 1.5rem;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.forget {
		color: $lila-light;
	}
	.account {
		color: #5e5e5e;
	}
}

.modal-btn {
	text-align: center;
	.btn {
		margin-bottom: 1.5rem;
		letter-spacing: 0;
		&:first-child {			
				background-color: $lila-light;
			&:hover {
				background-color: lighten($lila-light, 5%);
			}
		}
		&:last-child {
				background-color: $lila-dark;
			&:hover {				
				background-color: lighten($lila-dark, 5%);
			}
		}
		.btn-text-1 {
			color: $gray;
			position: relative;
			top: .2rem;
		}
		.btn-text-2 {
			font-size: 1.8rem;
			color: #fff;
		}
	}
}

.confirmation-modal {
	text-align: center;
	.modal-body {
		padding: .5rem 1.5rem 3rem 1.5rem; 
	}
}
	.container-confirm  {		
		p {
			font-size: 1.8rem;
			font-family: $os;
			margin-bottom: 2rem;
			color: $lila-dark;

		}
	}
		.modal-btn-confirm {
			text-align: center;	
			.btn-confirmation {
				padding: .7rem 4rem;
			}

		}

		/*------------------------------------*\
		    $rapport
		\*------------------------------------*/
		
.page-rapport {
	margin-top: 5rem;
}

.category-title {
	margin-top: .5rem!important;
	font-size: 3.2rem;
	color: $dark-gray-text;
}

.container-echantillon {
	margin: 2.5rem auto 3.2rem auto;
	font-family: $os;
	font-size: 1.2rem;
	color: $echantillon-text-color;
}

.echantillon {
	display: inline-block;
	vertical-align: middle;
	width: 1rem;
	height: $progress-height;
	background-color: $gray;
	margin-right: .5rem;
}

.echantillon-lg {
	width: 4rem;
}

.echantillon-sm {
	width: 3.2rem;
}

.echantillon-xs {
	width: 2rem;
}

.echantillon-green {
	background-color: $green;
}

.echantillon-yellow {
	background-color: $yellow;
}

.echantillon-red {
	background-color: $red;
}

.echantillon-text {
	display: inline-block;
	margin-right: 1.5rem;
}



.container-rapport-test{
	margin-top: 1.5rem;
	background-color: green;
}

.rapport-test-title {
	background-color: $media-bg;
	padding: 1.5rem 2rem 0 2rem;
}

.rapport-test-title-category {

	.container-progress {
		padding: 1.5rem 0 4rem 0;
	}

	header {
		h3 {
			margin-bottom: 1rem;
			font-family: $ob;
			font-size: 1.8rem;
			color: $black-rapport;
		}
	}	
}

.rapport-test-median {
	padding-left: 2rem;
	background-color: $gray-tab;	
	position: relative;
	height: 3.8rem;
	
	.rapport-test-median-content {
		line-height: 3.8rem;
		.echantillon-text {
			font-family: $ob;
			font-size: 1.3rem;
			text-transform: uppercase;
		}
	}

	> a {		
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 4.2rem;
		background-color: $dark-gray;
	}

	.rapport-test-median {

	}	
}

.rapport-test-content {
	padding: 1.5rem 2rem 2rem 2rem; 
	font-family: $os;
	font-size: 1.3rem;
}

.rapport-test-content-category {
	background-color: $media-bg;
	.media {
		margin: 0;
		p {
			margin-bottom: .5rem;
			line-height: 1.5;
			strong {
			font-family: $ob;
			}
		}	
	}	
	
}

.container-rapport-btn {
	min-height: 1px;
	margin-bottom: 3.5rem;
	@media(max-width: 991px) {
		margin-top: 1.5rem;
	}

	.link-files {
		display: inline-block;
		padding: 1.1rem 0 1.1rem 1rem;
		margin-bottom: 1.1rem;
		text-transform: uppercase;
		background-color: $lila-light;
		color: #fff;
		text-decoration: none;
		i, span {
			display: inline-block;
		}
		i {
			margin-right: .5rem;
			position: relative;
			bottom: .4rem;
			@media (min-width: 1024px) and (max-width: 1199px) {
				bottom: 0;
			}
		}		

		&:hover {
			background-color: $lila-dark;
		}

	}
}

.txt-link-rapport {
	font-family: $ob;
	font-size: 1.1rem;
	line-height: 1.2;
	letter-spacing: .1rem;

	@media (min-width: 1024px) and (max-width: 1199px) {
		font-size: .9rem;
	}
}

.other-tests {
	display: inline-block;
	background-color: $lila-dark;
	padding: 1.5rem;
	color: #fff!important;	
	text-transform: uppercase;
	text-decoration: none;

	i, span {
		display: block;
		text-align: center;
	}

	i {
		margin: 0 auto 1rem auto;
	}	
	
	&:hover {
		background-color: $lila-light;
		text-decoration: none;
	}
}

#detail {
	.container-rapport-test{
		margin-top: 0;		
		margin-bottom: 1.5rem;
	}
}

.apport-test-title-detail {	
	h3 {
		font-family: $ob;
		font-size: 1.8rem;
		color: $lila-dark;
	}

	p {
		margin-top: .5rem;
		margin-bottom: 0;
		padding-bottom: 1.5rem;
		font-family: $os;
		font-size: 1.3rem;
	}

}

	.rapport-type {
		font-family: $ob;
		font-size: 1.4rem;
		text-transform: uppercase;
	}

	.rapport-error {
		color: $red;
	}

	.rapport-success {
		color: $green;
	}

	.rapport-test-content-detail {
		padding: 1.5rem 2rem ; 
		border-top: 1px solid $gray;
		background-color: $gray-tab;
			
			> h5 {
				font-family: $ob;
				font-size: 1.3rem;
				color: $red;
				font-style: italic;
			}

			> p {
				margin-top: .5rem;
				margin-bottom: 2rem;
			}
	}

