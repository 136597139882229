html { 
	font-size: $font-size-md;	
}
body {
	// background-color: darken(#fff, 40%);
}

/*------------------------------------*\
    $
\*------------------------------------*/

.choisir-test-lg {
	margin-bottom: 3rem;	
	position: absolute;
}

.choix-item-title {	
	img, .container-item-title {		
	}
	.container-item-title {
		margin-left: 1rem;
		line-height: 1.2;
			.item-title-1 {
				font-size: 1.8rem;
			}
			.item-title-2 {
				font-size: 2.8rem;
			}
	}
}
.choisir-test-lg {
	.close-choisir-test-lg {	
			& span {
				top: -.4rem;
			}
		}
}


/*------------------------------------*\
    $rapport
\*------------------------------------*/
.rapport-test-median {
		height: 4.6rem;
		.rapport-test-median-content {
			line-height: 4.6rem;
		}	
}

