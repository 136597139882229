/*------------------------------------*\
    $footer
\*------------------------------------*/

footer {
  // position: absolute;
  // bottom: 0;
  // width: 100%;  
  // height: auto;
  background-color: #fff;
  color: $black;
  text-align: right;

  .footer-link {
      display: inline-block;
  		padding: 1.1rem;
  		font-family: $os;
  		font-size: 1.4rem;
  }
}