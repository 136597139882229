.breadcrumb {
	margin: 2rem 0 0 0;
	padding-top: 0;
	font-family: $os;
	font-size: 1.4rem;
	color: #fff;
	a:hover {
		color: inherit;
	}
}

.btn {
	padding: 1rem 1.8rem;
	font-family: $ob;
	font-size: 1.4rem;
	@include transition-duration($default-td); 

	&:hover {
		background-color: $lila-dark;
	}
	span {
		position: relative;
		top: -.2rem;
	}		

}

.btn-lg {
	padding: 1.7rem 3.7rem;
	font-family: $os;
	font-size: 1.8rem;
	text-transform: none;

}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 0 !important; // Move up sibling radios or checkboxes for tighter spacing
}

/*------------------------------------*\
    $form-group
\*------------------------------------*/
.form-group-lg {
	font-family: $os;
  font-size: 2rem;
  .form-control {
		box-shadow: none;
		color: $lila-dark;		
  }
}

/*------------------------------------*\
    $modal
\*------------------------------------*/

.close {
	opacity: 1;
}

/*------------------------------------*\
    $nav tabs
\*------------------------------------*/
.nav-tabs {
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    // Actual tabs (as links)
    > a {
      margin-right: 0;
      padding: 3rem 2rem;
      font-size: 1.8rem;
      font-family: $os;
      line-height: $line-height-base;
      background-color: $gray-tab;
      border-bottom: 1px solid $lila-dark!important;
      color: $light-gray-text;
      &:hover {
        border-color: $lila-dark;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        border: 1px solid $lila-dark!important;
        border-bottom-color: transparent!important;
      }
    }
  }
 
}

.nav-justified {
  > li {
    > a {
    	text-align: left;
      margin-bottom-color: red!important;
    }
  }  
}

	.tab-content {
		padding: 2rem;
	  border-left: 1px solid $lila-dark;
	  border-right: 1px solid $lila-dark;
	  border-bottom: 1px solid $lila-dark;	  
	}

	/*------------------------------------*\
	    $progress
	\*------------------------------------*/
.progress {
  height: $progress-height;
  margin-bottom: 0;
	overflow: visible;
	box-shadow: none;
	.progress-bar {
		box-shadow: none;			
	}
  .progress-text {
    min-width: 4rem;
    display: inline-block;
  	position: relative;	 
  	color: $black;
		top: 1rem;
		font-family: $os;
		font-size: 1.4rem;
		font-style: italic;
  }
  .progress-bar-success .progress-text {
    left: -2.3rem;
  }
  .progress-bar-warning .progress-text {
    right: 0rem;
  }
  .progress-bar-danger .progress-text {
    right: -2.3rem;
  }
}

